<template>
  <div class="p-20 bg-white">
    <div class="p-20 bg-f9">
      <div class="flex">
        <div
          class="rounded mr-20 border border-eee relative"
          style="width: 214px; height: 160px"
        >
          <img
            class="w-full h-full object-contain"
            v-if="data.targetPhoto"
            :src="data.targetPhoto.url"
            alt=""
          />
          <div
            class="absolute bottom-0 right-0 text-xs bg-black text-white"
            style="padding: 5px 8px 5px 12px"
          >
            {{ data.targetTypeName }}
          </div>
        </div>
        <div style="width: calc(100% - 234px)">
          <div class="font-bold ellipsis-2 mb-20">
            {{ data.targetName }}
          </div>
          <div class="p-10 ellipsis-4 bg-f9 text-666 text-sm mb-20">
            {{ data.targetDesc }}
          </div>
        </div>
      </div>
    </div>

    <div class="flex mt-40 justify-between items-center">
      <div class="text-lg font-bold mb-20">
        <span class="mr-10 text-primary align-middle">|</span>
        <span class="align-middle text-111">提问详情</span>
      </div>
      <a-select v-model="filter" @change="jumpPage(1)">
        <a-select-option :value="0">全部</a-select-option>
        <a-select-option :value="1">待审核</a-select-option>
        <a-select-option :value="2">审核成功</a-select-option>
        <a-select-option :value="3">审核失败</a-select-option>
      </a-select>
    </div>

    <div v-if="comments.length">
      <div
        class="mt-20 hover:shadow"
        v-for="item in comments"
        :key="item.id"
        @click="open1(item)"
      >
        <detail-comment-card :dataSource="item">
          <div class="flex justify-between items-end" slot="opt" @click.stop="">
            <a-space>
              <a-switch
                v-model="item.visible"
                @change="setCommentVisible(item.id, $event)"
              ></a-switch>
              <div class="text-999 text-xs">提问是否对外展示</div>
            </a-space>

            <a-button
              type="primary"
              @click="open(item)"
              v-if="item.status === 1"
              >审核</a-button
            >
          </div>
        </detail-comment-card>
      </div>
    </div>

    <list-empty v-else></list-empty>
    <div v-if="comments.length" class="text-center py-20">
      <a-pagination
        :pageSize="limit"
        v-model="page"
        :total="total"
        @change="jumpPage"
      ></a-pagination>
    </div>

    <a-drawer
      title="提问详情"
      placement="right"
      width="600"
      :visible="visible"
      @close="visible = false"
    >
      <div class="p-20 bg-f9">
        <div class="flex">
          <div
            class="rounded mr-20 border border-eee relative"
            style="width: 214px; height: 160px"
          >
            <img
              class="w-full h-full object-contain"
              v-if="current.targetPhoto"
              :src="current.targetPhoto.url"
              alt=""
            />
            <div
              class="absolute bottom-0 right-0 text-xs bg-black text-white"
              style="padding: 5px 8px 5px 12px"
            >
              {{ current.targetTypeName }}
            </div>
            <div
              class="
                absolute
                top-20
                left-0
                bg-primary
                text-xs text-white
                rounded-tr-full rounded-br-full
              "
              style="padding: 5px 10px"
            >
              {{ current.statusName }}
            </div>
          </div>
          <div style="width: calc(100% - 234px)">
            <div class="font-bold ellipsis-2 mb-20">
              {{ current.targetName }}
            </div>
            <div class="ellipsis-3 text-666 text-sm mb-20">
              {{ current.targetDesc }}
            </div>
          </div>
        </div>
      </div>
      <div class="mt-40 text-lg font-bold">
        {{ current.title }}
      </div>
      <div class="text-right mt-20">
        <span class="text-xs text-999">提问是否对外展示 </span>
        <a-switch
          v-model="current.visible"
          @change="setCommentVisible(current.id, $event)"
        ></a-switch>
      </div>
      <div class="text-right mt-20">
        <a-button
          type="primary"
          @click="open(current)"
          v-if="current.status === 1"
          >审核</a-button
        >
      </div>
    </a-drawer>

    <a-modal
      v-model="modal"
      title="审核"
      okText="审核"
      cancelText="取消"
      @ok="evaluteProComment"
    >
      <a-form-model :model="evaluate">
          <a-form-model-item>
            <a-select v-model="evaluate.AuditStatus">
              <a-select-option :value="2">审核通过</a-select-option>
              <a-select-option :value="3">审核失败</a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item v-if="evaluate.AuditStatus === 3">
            <gf-textarea
              :required="true"
              prop="Feedback"
              :max="300"
              :rows="5"
              v-model="evaluate.Feedback"
            ></gf-textarea>
          </a-form-model-item>
      </a-form-model>
    </a-modal>
  </div>
</template>

<script>
import {
  evaluateProComment,
  getProComments,
  setCommentVisible,
} from "@/api/comment.js";

export default {
  data() {
    return {
      data: {},
      comments: [],
      total: 0,
      page: 1,
      limit: 5,
      filter: 0,
      modal: false,
      evaluate: {},
      current: {},
      visible: false,
    };
  },
  created() {
    this.id = this.$route.params.id;
    this.type = this.$route.query.type;
    this.jumpPage(1);
  },
  methods: {
    setCommentVisible(id, e) {
      setCommentVisible(id, { public: e })
        .then(() => {
          this.$message.success("设置可见性成功");
        })
        .catch(() => {
          this.$message.success("设置可见性失败");
        })
        .finally(() => {
          this.jumpPage(this.page);
        });
    },
    getProComments(params) {
      params.target = this.id;
      getProComments(params).then(({ rows, count }) => {
        this.comments = rows;
        this.total = count;
        if (!this.data.id) {
          this.data = rows[0] || {};
        }
      });
    },
    jumpPage(e) {
      const params = {
        offset: (e - 1) * this.limit,
      };
      params.status = this.filter ? this.filter : undefined;
      params.limit = this.limit;
      this.getProComments(params);
    },
    evaluteProComment() {
      evaluateProComment(this.current.id, this.evaluate).then(() => {
        this.$message.success("审核成功");
        this.jumpPage(this.page);
        this.modal = false;
      });
    },
    open(item) {
      this.evaluate = {
        AuditStatus: 2,
        Feedback: "",
      };
      this.modal = true;
      this.visible = false
      this.current = item;
    },
    open1(item) {
      this.visible = true;
      this.current = item;
    },
  },
};
</script>